import { Link } from "gatsby"
import React, { useContext, useState } from "react"
import tw from "twin.macro"
import { Input } from "../Form"
import Textarea from "../Form/Textarea"
import { increaseStep, updateField } from "./actions"
import SponsorSignupContext from "./context"
import FormPage from "./FormPage"
import { useUsStates } from "../../hooks/useUsStatesList"
import Select from "../Form/Select"

const Info = ({ index, title, paragraph, ctaText }) => {
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidZipCode, setInvalidZipCode] = useState(false)
  const [optIn, setOptIn] = useState(false)

  const usStates = useUsStates()

  const [
    {
      active_step,
      name,
      company_name,
      company_type,
      company_website,
      email,
      phone,
      mailing_street_address,
      mailing_city,
      mailing_state,
      mailing_zip_code,
      use_case,
    },
    dispatch,
  ] = useContext(SponsorSignupContext)

  const validateForm = e => {
    e.preventDefault()
    setInvalidEmail(false)
    setInvalidZipCode(false)

    const email = document.getElementById("sponsor_email")
    const zip_code = document.getElementById("sponsor_zip_code")?.value;

    const validEmail = email.checkValidity()
    const validZipCode = /^[0-9]{5}(?:-[0-9]{4})?$/.test(zip_code)

    if (!validEmail) setInvalidEmail("Invalid email.")

    if(!validZipCode) setInvalidZipCode("Invalid zip code.")

    if(validEmail && validZipCode) {
      dispatch(increaseStep())
    }
  }

  return (
    <FormPage
      show={active_step === index}
      title={title}
      paragraph={paragraph}
      nextButtonText={ctaText}
      nextButtonOnClick={validateForm}
      nextButtonDisabled={
        !name ||
        !company_name ||
        !company_type ||
        !email ||
        !phone ||
        !mailing_street_address ||
        !mailing_city ||
        !mailing_state ||
        !mailing_zip_code ||
        !optIn ||
        !use_case
      }
    >
      <form id="sponsor_info" css={[tw`px-8 lg:px-16`]}>
        <Input
          name="name"
          label="Name"
          value={name}
          onChange={e => dispatch(updateField("name", e.target.value))}
          css={[tw`mb-8`]}
        />
        <Input
          name="company_name"
          label="Company Name"
          value={company_name}
          onChange={e => dispatch(updateField("company_name", e.target.value))}
          css={[tw`mb-8`]}
        />
        <Input
          name="company_type"
          label="Type of Business"
          value={company_type}
          onChange={e => dispatch(updateField("company_type", e.target.value))}
          css={[tw`mb-8`]}
        />
        <Input
          name="company_website"
          label="Website (optional)"
          value={company_website}
          onChange={e =>
            dispatch(updateField("company_website", e.target.value))
          }
          css={[tw`mb-8`]}
          type="url"
        />
        <Input
          id="sponsor_email"
          name="email"
          label="Email"
          type="email"
          value={email}
          onChange={e => dispatch(updateField("email", e.target.value))}
          css={[tw`mb-8`]}
          error={invalidEmail}
        />
        <Input
          name="phone"
          label="Contact Phone"
          type="tel"
          value={phone}
          onChange={e => dispatch(updateField("phone", e.target.value))}
          css={[tw`mb-8`]}
        />
        <Input
          name="mailing_street_address"
          label="Addresss"
          value={mailing_street_address}
          onChange={e => dispatch(updateField("mailing_street_address", e.target.value))}
          css={[tw`mb-8`]}
        />
        <Input
          name="mailing_city"
          label="City"
          value={mailing_city}
          onChange={e => dispatch(updateField("mailing_city", e.target.value))}
          css={[tw`mb-8`]}
        />
        <Select 
          name="mailing_state"
          label="State"
          value={mailing_state}
          onChange={e => dispatch(updateField("mailing_state", e.target.value))}
          css={tw`mb-8 h-12`}
        >
          <option css={tw`bg-black bg-opacity-70 text-white`}></option>
          { usStates.map((state, index) => (
            <option key={index} value={state} css={tw`bg-black bg-opacity-70 text-white`}>
             {state}
            </option>
           ))
          }
        </Select>
        <Input
          id="sponsor_zip_code"
          name="mailing_zip_code"
          label="Zip Code"
          type="text" 
          value={mailing_zip_code}
          onChange={e => dispatch(updateField("mailing_zip_code", e.target.value))}
          error={invalidZipCode}
        />
        <Textarea
          name="use_case"
          label="Brief explanation of how you will use the athlete's NIL"
          onChange={e => dispatch(updateField("use_case", e.target.value))}
          value={use_case}
          textWrap={true}
          css={[tw`mb-2 mt-12 md:mt-8 h-28 md:h-auto`]}
        />
        <p css={[tw`text-xs mb-8`]}>
          By submitting, you agree to our{" "}
          <a href="/terms-of-use" target="_blank" css={[tw`underline`]}>
            Terms
          </a>
          {" "}and{" "}
          <a href="/privacy-policy" target="_blank" css={[tw`underline`]}>
            Privacy Policy
          </a>
          .
        </p>
        <label for="sponsor_opt_in" css={[tw`inline-flex items-center mb-8`]}>
          <input
            id="sponsor_opt_in"
            name="sponsor_opt_in"
            type="checkbox"
            css={[tw`sr-only`]} 
            onChange={e => setOptIn(e.target.checked)}
          />
          <div css={[tw`border-2 border-primary h-6 w-6 rounded mr-2 p-2 lg:p-0.5`]}>
            <div css={[tw`w-full h-full rounded-sm`]} />
          </div>
          <span>
            I agree to TTC's Terms and Privacy Policy
          </span>
        </label>
      </form>
    </FormPage>
  )
}

export default Info
